import { FormHelperText, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import {
  ApiRequest_Create_PropertyLocation,
  useCreatePropertyLocation,
} from '@/api/endpoints/propertyLocation/useCreatePropertyLocation';
import { PropertyLocationType } from '@/api/enums';
import { OnboardingRoutes } from '@/config/routes';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { getNextOnboardingStep } from '@/utils/onboarding';

import FormAddressAutocomplete from '@/components/form/FormAddressAutocomplete';
import FormSelect from '@/components/form/FormSelect';
import FormTextField from '@/components/form/FormTextField';
import MapboxAddressMap from '@/components/mapbox/MapboxAddressMap';
import {
  propertyLocationDeclaredUnitsCountOptions,
  propertyLocationTypeOptions,
} from '@/components/propertyLocation/options';

import SplitMuiPage from '../common/SplitMuiPage';

const OnboardingLocationPage: React.FC = () => {
  const { refetch, isFetching } = usePropertyManager();
  const { onboardingProgress } = useOnboardingProgress();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ApiRequest_Create_PropertyLocation>({
    defaultValues: {
      name: '',
      address_text: '',
      type: undefined,
      declared_units_count: undefined,
    },
    mode: 'onBlur',
  });

  const history = useHistory();

  const [formError, setFormError] = useState<Error | null>(null);

  const addressText = watch('address_text');
  const propertyLocationType = watch('type');

  const createPropertyLocationMutation = useCreatePropertyLocation({
    onSuccess: data => {
      history.replace(
        ...getNextOnboardingStep(OnboardingRoutes.propertyLocationCreate, {
          ...onboardingProgress,
          firstPropertyLocationId: data.id,
          hasPropertyLocation: true,
        })
      );
    },
    onError: error => {
      setFormError((error?.response?.data ?? error) as Error);
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Create_PropertyLocation) => createPropertyLocationMutation.mutateAsync(data),
    [createPropertyLocationMutation]
  );

  return (
    <SplitMuiPage
      sideContent={<MapboxAddressMap addressText={addressText} />}
      isLoading={isFetching}
    >
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={3} width="100%">
        <Stack spacing={1.5} mb={1}>
          <Typography variant="h3">Create a property</Typography>

          <Typography variant="body2">
            Enter your building details and upload tenant information.
          </Typography>
        </Stack>

        <Stack spacing={2.5}>
          <FormTextField
            label="Property Name"
            placeholder="Enter property name"
            error={!!errors.name}
            helperText={errors.name?.message?.toString()}
            required
            {...register('name', {
              required: 'Property Name is required',
            })}
            control={control}
          />

          <FormAddressAutocomplete
            label="Property Mailing Address"
            placeholder="Enter property mailing address"
            error={!!errors.address_text}
            helperText={errors.address_text?.message?.toString()}
            required
            {...register('address_text', {
              required: 'Property Mailing Address is required',
            })}
            control={control}
          />

          <FormSelect
            label="Property Type"
            placeholder="Select property type"
            error={!!errors.type}
            helperText={errors.type?.message?.toString()}
            required
            {...register('type', {
              required: 'Property Type is required',
            })}
            control={control}
            options={propertyLocationTypeOptions}
          />

          {propertyLocationType === PropertyLocationType.MULTI_UNIT && (
            <FormSelect
              label="Units Count"
              placeholder="Select units count"
              error={!!errors.declared_units_count}
              helperText={errors.declared_units_count?.message?.toString()}
              required
              {...register('declared_units_count', {
                required: 'Units Count is required',
              })}
              control={control}
              options={propertyLocationDeclaredUnitsCountOptions}
            />
          )}

          {!!formError && <FormHelperText error>{formError.message}</FormHelperText>}
        </Stack>

        <Stack spacing={1} mb={1}>
          <LoadingButton loading={isSubmitting} fullWidth variant="contained" type="submit">
            Next
          </LoadingButton>
        </Stack>
      </Stack>
    </SplitMuiPage>
  );
};

export default OnboardingLocationPage;
