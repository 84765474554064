import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useMemo } from 'react';
import Joyride, { CallBackProps, Step, STATUS } from 'react-joyride';

import { getPropertyManagerMenuItems } from '@/@mantis/menu-items/property-manager-menu-items';
import { NavItemType } from '@/@mantis/types/menu';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isSingleFamilyAccount: boolean;
}

/**
 * A component that provides a guided tour of the menu items using react-joyride
 */
const MenuTourGuide: React.FC<Props> = ({ isOpen, onClose, isSingleFamilyAccount }) => {
  const theme = useTheme();

  // Helper function to flatten nested menu items
  const flattenMenuItems = useCallback(
    (items: NavItemType[]): NavItemType[] =>
      items.reduce<NavItemType[]>((acc, item) => {
        acc.push(item);

        if (item.children) {
          acc.push(...flattenMenuItems(item.children));
        }

        return acc;
      }, []),
    []
  );

  const steps: Step[] = useMemo(() => {
    const menuItems = getPropertyManagerMenuItems(isSingleFamilyAccount);
    const flatMenuItems = flattenMenuItems(menuItems);

    // Create steps for each non-hidden menu item that has a target element
    const tourSteps: Step[] = flatMenuItems
      .filter(item => item.type !== 'collapse' && item.type !== 'divider')
      .map(item => ({
        target: `#${item.id}`,
        content: (
          <Box>
            <Typography variant="subtitle1">{item.title}</Typography>
            <Typography variant="body2">
              {item.description ||
                (item.type === 'group' && item.children
                  ? 'This is a menu section with child items.'
                  : 'Navigate here to access this feature.')}
            </Typography>
          </Box>
        ),
        disableBeacon: true,
        placement: 'right',
      }));

    // Include property location dropdown in the tour
    const additionalSteps: Step[] = [
      {
        target: '#property-location-selector',
        content: (
          <Box>
            <Typography variant="subtitle1">Your Property</Typography>
            <Typography variant="body2">
              Use this dropdown to switch between different properties you manage. Changing the
              property will update the data shown throughout the application.
            </Typography>
          </Box>
        ),
        disableBeacon: true,
        placement: 'top',
      },
    ];

    return [...tourSteps, ...additionalSteps];
  }, [isSingleFamilyAccount, flattenMenuItems]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action } = data;

    // End the tour when it's finished, skipped or the close button is clicked
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED || action === 'close') {
      onClose();
    }
  };

  return (
    <Joyride
      steps={steps}
      run={isOpen}
      continuous
      scrollToFirstStep
      showProgress
      showSkipButton
      disableOverlayClose
      spotlightClicks
      callback={handleJoyrideCallback}
      styles={{
        options: {
          primaryColor: theme.palette.primary.main,
          backgroundColor: theme.palette.background.paper,
          textColor: theme.palette.text.primary,
          zIndex: 2000,
        },
        buttonNext: {
          backgroundColor: theme.palette.primary.main,
        },
        buttonBack: {
          color: theme.palette.primary.main,
        },
      }}
    />
  );
};

export default MenuTourGuide;
