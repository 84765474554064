import { Link, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { AlertTriangleIcon } from '@/assets/icons/AlertTriangleIcon';

interface Props extends PropsWithChildren {
  title?: string;
}

const ErrorStateWithMuiPageWrapper: React.FC<Props> = ({
  title = 'Something went wrong',
  children = (
    <Typography variant="body1" textAlign="center">
      It's us, not you.
      <br />
      Please try again in a couple of minutes
      <br />
      or let us know about the issue at{' '}
      <Link component="a" href="mailto:hello@terr.ai?subject=Error%20Report">
        hello@terr.ai
      </Link>
    </Typography>
  ),
}) => (
  <MuiPageWrapper>
    <Stack display="flex" justifyContent="center" alignItems="center" flex="1 1 100%" spacing={1.5}>
      <AlertTriangleIcon color="error" />
      <Typography variant="h6" color="error">
        {title}
      </Typography>

      {children}
    </Stack>
  </MuiPageWrapper>
);

export default ErrorStateWithMuiPageWrapper;
