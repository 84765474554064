import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiResponse_Create_Unit,
  useCreateUnit,
  ApiRequest_Create_Unit,
} from '@/api/endpoints/unit/useCreateUnit';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';

import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  buildingId: string;
  onClose: () => void;
  onUnitCreated?: (unit: ApiResponse_Create_Unit) => void;
}

const UnitCreateModal: React.FC<Props> = ({ onClose, onUnitCreated, buildingId }) => {
  const { setOnboardingProgress } = useOnboardingProgress();
  const form = useForm<ApiRequest_Create_Unit>({
    defaultValues: {
      building_id: Number(buildingId),
    },
  });
  const {
    register,
    control,
    formState: { errors },
  } = form;

  const createUnitMutation = useCreateUnit({
    onSuccess: data => {
      setOnboardingProgress({
        hasUnit: true,
        firstUnitId: data.id,
      });
      onUnitCreated?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Create_Unit) => createUnitMutation.mutateAsync(data),
    [createUnitMutation]
  );

  return (
    <ModalWithForm
      form={form}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="xs"
      title="Create Unit"
    >
      <FormTextField
        label="Name"
        required
        {...register('name', {
          required: 'Name is required',
        })}
        error={!!errors.name}
        helperText={errors.name?.message?.toString()}
        control={control}
        size="small"
      />
    </ModalWithForm>
  );
};

export default UnitCreateModal;
