import { alpha, useTheme, Box } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { usePropertyManager } from '@/context/PropertyManagerProvider';

import FormPropertyLocationSelect from '@/components/form/FormPropertyLocationSelect';

interface FormData {
  propertyLocationId: number;
}

const NavPropertyLocationSelect: React.FC = () => {
  const { selectedPropertyLocation, setSelectedPropertyLocationId } = usePropertyManager();
  const theme = useTheme();

  const { control, register, watch } = useForm<FormData>({
    defaultValues: {
      propertyLocationId: selectedPropertyLocation?.id,
    },
  });

  const propertyLocationId = watch('propertyLocationId');

  useEffect(() => {
    if (propertyLocationId === selectedPropertyLocation?.id) return;
    if (!propertyLocationId) return;

    setSelectedPropertyLocationId(propertyLocationId);
  }, [selectedPropertyLocation, propertyLocationId, setSelectedPropertyLocationId]);

  return (
    <Box id="property-location-selector">
      <FormPropertyLocationSelect
        control={control}
        {...register('propertyLocationId')}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.primary.main, 0.25),
            transition: theme.transitions.create(['border-color'], {
              duration: theme.transitions.duration.shortest,
            }),
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.primary.main, 0.5),
          },
        }}
      />
    </Box>
  );
};

export default NavPropertyLocationSelect;
